<template>
  <div class="container-fluid shadow p-3 pe-5 ps-5 mb-5">
    <div class="row flex-nowrap">
      <div class="col navbar-light p-0 m-0">
        <span class="h3">Roles</span>
        <div class="float-end">
          <display-picture :is-left="true" class="rounded-circle"></display-picture>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <h5>Restricted Users</h5>
      <data-table
          :headers="headers"
          :actions="userActions"
          :items="restrictedUsers"
      />
      <h5>User Roles</h5>
      <data-table
          :headers="headers"
          :actions="actions"
          :items="items"
      />
    </div>
  </div>
</template>

<script>

import DataTable from 'data-table/src/components/DataTable'
import {services} from "../service/user-api";
import ModalService from "modal/src/services/Modal";
import ToastService from "toast/src/services/toast";
import DisplayPicture from 'node-back-client/src/components/DisplayPicture'
export default {
  components: {DataTable,DisplayPicture},
  name: "UserApplication",
  async created() {
    let data = await services.user.GetUsers({},0,0)
    this.users = data.data;
    await this.getRole();
  },
  data() {
    return {
      items:[],
      restrictedUsers:[],
      users:[],
      headers : {
        _id:"#",
        username:"UserName"
      },
      userActions: [
        {
          classNames:"fa fa-plus text-success",
          callback: (entity) => {
            ModalService.Show({
              title: "Do you want to add",
              message : `Add Role`,
            }, async () => {

              let params = this.$route.params;
              let {application_id} = params;

              await services.user_application.post("",{
                user_id:entity._id,
                application_id
              });
              await this.getRole();
              ToastService.Notify({
                title:"Success",
                message:"Role has been added."
              });
            })
          }
        }
      ],
      actions: [
        {
          classNames:"fa fa-trash text-danger",
          callback: (entity) => {
            ModalService.Show({
              title: "Do you want to remove",
              message : `Remove Role`,
            }, async () => {
              await services.user_application.remove(entity._id);
              await this.getRole();
              ToastService.Notify({
                title:"Success",
                message:"Role has been deleted."
              });
            })
          }
        }
      ]
    }
  },
  methods:{
    async getRole() {
      let params = this.$route.params;
      let {application_id} = params;
      let filter =  { application_id };
      let data = await services.user_application.post("fetch", {
        filter,skip:0,limit:0
      });


      this.items = data.data.filter(x => {
        let u = this.users.find(y => y._id === x.user_id);

        if(!u) {
          return false
        }

        x.username = u.username;
        return true;
      });
      this.restrictedUsers = this.users.filter(x => this.items.findIndex(y => y.user_id === x._id) === -1);

    },
  }
}
</script>

<style scoped>

</style>